import "./Footer.css";
import amelogo from "../../assets/amelogo.png"
import oplogo from "../../assets/oplogo.svg"
import pinatalogo from "../../assets/pinata.svg"
function Footer() {
  return (
    <div className="Footer">

        <div className="Poweredby">
            <div className="PoweredbyTitle">Powered by</div>
            <div className="PoweredbyLogo">
                <a href="https://ame.network/" target="_blank"> <img src={amelogo} height={24}/></a>
               
                <a href="https://www.optimism.io/" target="_blank" className="op"> <img src={oplogo} height={12}/></a>
                <a href="https://pinata.cloud/" target="_blank"> <img src={pinatalogo} height={16}/></a>
            </div>
        </div>

        <div className="Copyright">@2024 Jutsu Hub</div>
    </div>
  );
}
export default Footer;