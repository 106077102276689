
import List from "./pages/List/List"
import Detail from "./pages/Detail/Detail"
import { Navigate } from "react-router-dom";
import Mint from "./pages/Mint/Mint";
const routes = [
  {
    path: "/",
    element:<Navigate to="/list/1" replace />,
  }, {
    path: "/list/:page",
    element: <List/>,
  },  {
    path: "/detail/:name",
    element: <Detail/>,
  },  {
    path: "/mint",
    element: <Mint/>,
  }
  
];
export default routes;


