import "./Header.css";
import logo from "../../assets/jutsulogo.svg"
function Header() {
  return (
    <div className="Header">
      <a href="/">
        <img src={logo} width={130}/>
      </a>
      <ul className="Nav">
        <li>
          <a href="/mint">Jutsu Hub NFT Mint</a>
        </li>
        <li>
          <a href="https://github.com/AmeNetwork/jutsu-hub" target="_blank">Github</a>
        </li>
      </ul>
    </div>
  );
}
export default Header;
