import { useState, useEffect } from "react";
import "./Mint.css";
import NFTImage from "../../assets/NFTImage.png";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import toast, { Toaster } from "react-hot-toast";
import config from "../../config.json";
import {
  hexToNumber,
  numberToHex,
  createWalletClient,
  defineChain,
  http,
  custom,
} from "viem";

import NFTABI from "./NFT.json";

function Mint() {
  const [currentAddress, setCurrentAddress] = useState("");

  useEffect(() => {
    (async function fetchData() {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      setCurrentAddress(accounts[0]);
    })();
  }, []);

  var getShortAddress = (address) => {
    if (address != undefined) {
      if (address.length != undefined) {
        let end = address.length;
        let addressShort =
          address.substring(0, 5) + "..." + address.substring(end - 4, end);
        return addressShort;
      }
    }
  };

  const mintNFT = async () => {
    toast.promise(
      (async function fetchData() {
        const chain = defineChain({
          id: config.chainId,
          name: "localhost",
          nativeCurrency: {
            decimals: 18,
            name: "Ether",
            symbol: "ETH",
          },
          rpcUrls: {
            default: {
              http: [config.rpc],
            },
          },
          contracts: {
            nft: config.nft,
          },
        });

        console.log("chain", chain.rpcUrls.default.http[0]);

        const walletNetwork = {
          Network: {
            chainId: numberToHex(chain.id),
            chainName: chain.name,
            rpcUrls: [chain.rpcUrls.default.http[0]],
            nativeCurrency: {
              name: "ETH",
              symbol: "ETH",
              decimals: 18,
            },
          },
        };

        if (typeof window.ethereum !== "undefined") {
          if (currentAddress == null) {
            await window.ethereum.request({
              method: "eth_requestAccounts",
            });
          }
          const accounts = await window.ethereum.request({
            method: "eth_accounts",
          });
          setCurrentAddress(accounts[0]);
          await checkNetwork(chain, walletNetwork);

          const walletClient = createWalletClient({
            chain,
            transport: custom(window.ethereum),
          });

          await walletClient.writeContract({
            address: chain.contracts.nft,
            abi: NFTABI,
            functionName: "mint",
            account: accounts[0],
          });
        } else {
        }
      })(),

      {
        loading: "Loading",
        success: "Success",
        error: "Fail",
      }
    );
  };

  const checkNetwork = async (chain, walletNetwork) => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    if (hexToNumber(chainId) != chain.chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: walletNetwork.Network.chainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [walletNetwork.Network],
            });
          } catch (addError) {
            console.log(addError);
          }
        }
      }
    }
  };

  return (
    <div className="MintContainer">
      <Toaster />
      <Header></Header>
      <div className="MintTitle"> Jutsu Hub NFT Mint</div>
      <div className="MintDescription">
        The total amount of Ame Network x Jutsu Hub NFT is 8,000, deployed on
        the OP mainnet, and each address can mint one for free. Please note that
        it has no investment value, it is just a souvenir, and no token airdrop
        will be promised.
      </div>

      <div className="MintNFTImage">
        <img src={NFTImage} width="100%" />
      </div>
      <div className="MintButton" onClick={mintNFT}>
        Free Mint
      </div>
      <div className="MintWallet">
        Your Wallet:{getShortAddress(currentAddress)}
      </div>

      <Footer></Footer>

      {/* <div hidden={hideTip} className="MintTip">Please Connect Wallet and Change Network to Optimism Mainnet</div> */}
    </div>
  );
}

export default Mint;
