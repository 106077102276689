import { ConfigProvider, Pagination } from "antd";
import "./List.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AmeViem } from "ame-sdk";
import { format } from "date-fns";
import config from "../../config.json";
import Avatar from "boring-avatars";
import CopyToClipboard from "react-copy-to-clipboard";
import Header from "../../components/Header/Header";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../../components/Footer/Footer";
function List() {
  const params = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(params.page);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(1);
  const [list, setList] = useState([]);

  const ameViem = new AmeViem(
    {
      id: config.chainId,
      rpcUrls: {
        default: { http: [config.rpc] },
      },
    },
    config.contract
  );

  useEffect(() => {
    (async function fetchData() {
      const projectTotalEncode = await ameViem.sendGetRequest(
        "getProjectTotal",
        ""
      );

      var projectTotalDecode = ameViem.decodeResponseData(
        [{ name: "projectTotal", type: "uint256" }],
        projectTotalEncode
      );

      setTotal(parseInt(projectTotalDecode));

      var getProjectsEncode = ameViem.encodeRequestParams(
        [
          { name: "pageNumber", type: "uint256" },
          { name: "pageSize", type: "uint256" },
        ],
        [params.page, size]
      );

      const projectsEncode = await ameViem.sendGetRequest(
        "getProjects",
        getProjectsEncode
      );

      var projectsDecode = ameViem.decodeResponseData(
        [
          { name: "id", type: "uint256[]" },
          { name: "name", type: "string[]" },
          { name: "owner", type: "address[]" },
          { name: "version", type: "string[]" },
          { name: "cid", type: "string[]" },
          { name: "date", type: "uint256[]" },
        ],
        projectsEncode
      );

      const transformedProjects = projectsDecode[0].map((id, index) => ({
        id: parseInt(id),
        name: projectsDecode[1][index],
        owner: projectsDecode[2][index],
        version: projectsDecode[3][index],
        cid: projectsDecode[4][index],
        date: format(projectsDecode[5][index].toString() * 1000, "MMM d"),
      }));

      console.log("transformedProjects", transformedProjects);

      setList(transformedProjects);

      setCurrentPage(params.page);
    })();
  }, [params.page]);

  const changePageNum = (page, pageSize) => {
    console.log("CurrentPage", page);
    setCurrentPage(page);
    navigate("/list/" + page);
  };

  var getShortAddress = (address) => {
    if (address != undefined) {
      if (address.length != undefined) {
        let end = address.length;
        let addressShort =
          address.substring(0, 5) + "..." + address.substring(end - 4, end);
        return addressShort;
      }
    }
  };

  return (
    <div>
      <Header></Header>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />
      <div className="Banner">
        <div className="BannerTitle">The Solidity Components Registry</div>
        <CopyToClipboard text="npm install -g jutsu-cli">
          <div
            className="BannerCopy"
            onClick={() => {
              toast("Copied!");
              console.log(1);
            }}
          >
            <div>npm install -g jutsu-cli</div>

            <div className="BannerCopyButton">Copy</div>
          </div>
        </CopyToClipboard>
      </div>
      <div>
        {list.length != 0 ? (
          <div className="List">
            {list.map((item, index) => (
              <a href={"/detail/" + item.name} key={index}>
                <div className="ListItem">
                  <div className="ListItemName">{item.name}</div>

                  <div className="ListItemDetail">
                    <div className="ListItemAvatar">
                      <Avatar
                        width={24}
                        name={item.owner}
                        colors={[
                          "#ffe3b3",
                          "#ff9a52",
                          "#ff5252",
                          "#c91e5a",
                          "#3d2922",
                        ]}
                        variant="bauhaus"
                      />
                    </div>
                    <div className="ListItemAddress">
                      {getShortAddress(item.owner)}
                    </div>
                    <div className="ListItemVersion">v{item.version}</div>
                    <div className="ListItemDate">{item.date}</div>
                  </div>
                </div>
              </a>
            ))}

            <div className="ListPage">
              <ConfigProvider
                theme={{
                  token: {
                    colorText: "#fff",
                  },
                }}
              >
                <Pagination
                  current={currentPage}
                  simple={{
                    readOnly: true,
                  }}
                  defaultCurrent={params.page}
                  defaultPageSize={size}
                  total={total}
                  onChange={changePageNum}
                />
              </ConfigProvider>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default List;
