import { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { AmeViem } from "ame-sdk";
import { format } from "date-fns";
import config from "../../config.json";
import Markdown from "react-markdown";
import Header from "../../components/Header/Header";
import "./Detail.css";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../../components/Footer/Footer";
function Detail() {
  const params = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState("");

  const ameViem = new AmeViem(
    {
      id: config.chainId,
      rpcUrls: {
        default: { http: [config.rpc] },
      },
    },
    config.contract
  );

  var getShortAddress = (address) => {
    if (address != undefined) {
      if (address.length != undefined) {
        let end = address.length;
        let addressShort =
          address.substring(0, 5) + "..." + address.substring(end - 4, end);
        return addressShort;
      }
    }
  };

  useEffect(() => {
    (async function fetchData() {
      var getProjectEncode = ameViem.encodeRequestParams(
        [{ name: "name", type: "string" }],
        [params.name]
      );

      const projectEncode = await ameViem.sendGetRequest(
        "getProject",
        getProjectEncode
      );

      var projectsDecode = ameViem.decodeResponseData(
        [
          { name: "id", type: "uint256" },
          { name: "name", type: "string" },
          { name: "owner", type: "address" },
          { name: "version", type: "string" },
          { name: "cid", type: "string" },
          { name: "date", type: "uint256" },
        ],
        projectEncode
      );

      var projectJSON = {
        id: parseInt(projectsDecode[0]),
        name: projectsDecode[1],
        owner: projectsDecode[2],
        version: projectsDecode[3],
        cid: projectsDecode[4],
        date: format(projectsDecode[5].toString() * 1000, "MMM d"),
      };

      console.log("projectsDecode", projectsDecode);

      const projectConfig = await fetch(
        "https://gateway.pinata.cloud/ipfs/" +
          projectsDecode[4] +
          "/config.json"
      );
      const projectConfigData = await projectConfig.json();

      const projectReadme = await fetch(
        "https://gateway.pinata.cloud/ipfs/" + projectsDecode[4] + "/README.md"
      );
      const projectReadmeData = await projectReadme.text();

      console.log("projectReadmeData", projectReadmeData);

      projectJSON.config = projectConfigData;

      projectJSON.readme = projectReadmeData;

      setProject(projectJSON);
    })();
  }, [params.name]);

  return project != "" ? (
    <div>
      <Header></Header>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1000,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />
      
      <div className="Detail">
        <div className="DetailBack" onClick={()=>{
          navigate(-1);
        }}>Back</div>
        <div className="DetailName">{project.name}</div>
        <div className="DetailDescription">{project.config.description}</div>

        <div className="DetailItem">
          <div className="DetailItemTitle">Install</div>

          <CopyToClipboard text={"jutsu add " + project.name}>
            <div
              style={{cursor:"pointer"}}
              className="DetailItemValue"
              onClick={() => {
                toast("Copied!");
                console.log(1);
              }}
            >
              jutsu add {project.name}
            </div>
          </CopyToClipboard>
        </div>

        <div className="DetailItem">
          <div className="DetailItemTitle">Repository</div>
          <div className="DetailItemValue">
            <a href={project.config.github} target="_blank" className="DetailItemValueWebsite">
              {project.config.github}
            </a>

            <a href={project.config.github} target="_blank" className="DetailItemValueMobile">
              {getShortAddress(project.config.github)}
            </a>

          </div>
        </div>

        <div className="DetailItem">
          <div className="DetailItemTitle">Version</div>
          <div className="DetailItemValue">
            {" "}
            v {project.version} • Published {project.date}
          </div>
        </div>

        <div className="DetailItem">
          <div className="DetailItemTitle">License</div>
          <div className="DetailItemValue">{project.config.license}</div>
        </div>

        <div className="DetailItem">
          <div className="DetailItemTitle">Owner</div>

          <div className="DetailItemValue">
            <span className="DetailItemValueWebsite">{project.owner}</span>
            <span className="DetailItemValueMobile">
              {getShortAddress(project.owner)}
            </span>
          </div>
        </div>

        <div className="DetailItem">
          <div className="DetailItemTitle">CID</div>
          <div className="DetailItemValue">
            <a
              href={"https://gateway.pinata.cloud/ipfs/" + project.cid}
              target="_blank"
            >
              <span className="DetailItemValueWebsite"> {project.cid}</span>
              <span className="DetailItemValueMobile">
                {getShortAddress(project.cid)}
              </span>
            </a>
          </div>
        </div>

        <div className="DetailReadmeTitle">Readme</div>
        <Markdown>{project.readme}</Markdown>
        <div className="DetailReadme"></div>
      </div>
      <Footer></Footer>
    </div>
  ) : (
    <div></div>
  );
}
export default Detail;
